<template lang="pug">
  .join-container
    //- .top-box
      //- p 中医药产业发展研究中心
      //- img(src="../assets/img/strategicJoin/bsns-tit-en.png" class="tit-en")
    img.top-img(src="../assets/img/strategicJoin/business-bg2.jpg" v-if="$store.state.pcshow")
    .top-box(v-else)
    .develop-box
      .tit-img
        .left
          p.tit 中心简介&发展战略
          p.detail 为助推中医药产业发展，由大理大学和天津中科医创科技有限公司签订战略合作协议，联合合作成立“中医药产业发展研究中心”。开展中医药标准化及相关领域的课题研究，以及产业化合作和人才培养。
          p.detail 中心建设紧紧围绕西南是我国中医药资源最丰富的地区、也是我国中药材的重要道地产区和传统产区之一的资源和产区优势，基于中药材生产的品种化、生态化和产品化的迫切技术需求，开展中药材种质创新、生态种植及产品研发的技术创新及产业化，从而促进国家及区域中医药产业持续、健康发展。
        .rgt 
          img(src="../assets/img/strategicJoin/bsns-dldx.jpg")
      p.detail2 
        span 中心运营主体为
        span(class="green-font") 中科医创科技有限公司
        span ，以立足云南、面向西南，辐射全国，推动中医药产业发展科技创新，为大理大学优势学科基地在国家技术创新体系中发挥应有的作用提供平台支撑。
      p.detail2 产业发展研究中心紧紧围绕产业发展及发展中的瓶颈和关键共性问题，有针对性地开展科技研究、示范及成果转化，满足中医药产业发展对关键技术的需求。根据学科和中心发展的需要，有计划和有重点地吸收和培养一批优秀的中医药产业发展链条中青年创新创业骨干人才，其中包括在学术上有突出造诣、在国内外产生较大影响力的学科带头人，在管理、经营和产业化开发方面培养有突出能力的经营管理精英，形成一流的中医药产业链科技创新创业和经营管理的优秀团队。
    .member-box
      p.member-tit 中心主要成员
      .member-con
        .member-item  
          .lft 
            img(src="../assets/img/strategicJoin/member-dbz.png")
          .right 
            p.name 王冰
            p.desc 高级信息化工程师，高级经济师，武汉大学MBA，原济民可信集团副总裁
        .member-item  
          .lft 
            img(src="../assets/img/strategicJoin/member-zmc.jpg")
          .right 
            p.name 张满常
            p.desc 主任药师，硕士研究生导师，保山市食品药品检验研究院书记，院长，中医药产业发展研究中心专家顾问。
        .member-item  
          .lft.img4-box
            img(src="../assets/img/strategicJoin/member-axd2.png")
          .right 
            p.name 安学东
            p.desc 澳大利亚皇家理工医学博士，中科医创医学总监，原浙江海正药业股份有限公司医学总监。
        .member-item  
          .lft.img4-box 
            img(src="../assets/img/strategicJoin/member-dzg.png",)
          .right 
            p.name 段志刚
            p.desc 医学硕士，中科医创CMO，大理大学中医药产业发展研究中心主任，原天津秀鹏生物技术开发有限公司副总经理。
    .upgrade-con 
      .tit-box
        p
        div.divider-text 助力产业升级
        p
      .upgrade-content 
        .lft 
          img(src="../assets/img/strategicJoin/result.jpg")
        .rgt 
          p {{upgradeData[0]}}
          p {{upgradeData[1]}}
    .project-con 
      .tit-box
        p
        div.divider-text 中心在研项目
        p
      .project-content
        .project-item(v-for="(item, index) in projectList" :key="index")
          .project-tit {{ item.title }}
          p.name {{ item.name }}
          p.desc {{ item.desc }}
          img(:src="item.logo")
</template>
<script>
export default {
  name: "strategicJoin",
  data() {
    return {
      upgradeData: [
        "中科医创科技有限公司是中关村高新技术企业，致力于医疗大数据产业链整合SAAS服务。独创的研产销一体化数智转型模式得到包括恒瑞医药、国药集团等央企和上市公司的青睐，受大理大学邀请共建中医药产业发展研究中心，协助云南腾药制药股份有限公司、昆明赛诺制药股份有限公司等本土企业数智转型升级。",
        "医疗行业改革进入深水区，医保穿底，商保介入，中科医创独创的数智转型模式能与药企更好的融合，促动医疗营销“从线下向线上，从院内向院外，从医生向患者”转变。产业发展研究中心通过“硬件软件化，服务产品化，数据资源化”的数智经济竞争模式，将赋能本地药企经济转型和高质量发展，帮助更多的企业在医疗体制改革的寒冬中实现“换道超车”。",
      ],
      projectList: [
        {
          title: "蛭蛇通络胶囊",
          name: "蛭蛇通络胶囊产品适应症拓展及机理研究I期",
          desc:
            "基于蛭蛇通络胶囊含水蛭素等相关有效成分，开展“蛭蛇通络胶囊作用心梗患者的药物机理研究”。",
          logo: require("../assets/img/strategicJoin/sxjm-logo.png"),
        },
        {
          title: "心脉隆注射液",
          name: "心脉隆注射液产品适应症拓展研究I期",
          desc:
            "心脉隆注射液再临床实证中关于“中医证候评分与检测指标：BNP/NT-proBNP、LVEF、LVEDD、6MWD、尿量等的相关性研究”。",
          logo: require("../assets/img/strategicJoin/ynty-logo.png"),
        },
      ],
    };
  },
};
</script>
<style scoped lang="stylus">
@media screen and (min-width: 960px) {
  .join-container{
    p{
      margin: 0;
    }
    .top-img{
      margin-top: 90px;
      width: 100%;
      height: auto;
    }
    .develop-box{
      width: 1200px;
      margin: 90px  auto 0;
      .tit{
        font-size: 34px;
        font-weight: 700;
        color: #333333;
        border-left:3px solid #02b2b5;
        padding-left: 11px;
        margin-bottom: 40px;
      }
      .green-font{
        color: #02b2b5;
      }
      .tit-img{
        display: flex;
        align-items: center;
        .rgt{
          width: 40%;
        }
        img{
          width: 100%;
          height: auto;
          // width: 693px;
          // height: 469px;
        }
        .left{
          width: 60%;
        }
      }
      .detail,.detail2{
        text-indent: 2em;
        font-size: 15px;
        line-height: 2;
        margin-right: 40px;
        margin-bottom: 35px;
        color: #666;
      }
      .detail2{
        margin-right: 0;
      }
    }
    .member-box {
      margin: 30px auto;
      width: 1200px;
      box-sizing: border-box;
      .member-tit{
        font-size: 34px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 50px;
        margin-top: 90px;
      }
      .member-con{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .member-item{
        display: flex;
        background: #adbebe;
        border-radius: 10px;
        padding: 25px 30px 35px;
        width: calc(50% - 15px);
        box-sizing: border-box;
        margin-bottom: 30px;
        .lft{
          padding: 2px;
          background: #fff;
          box-sizing: border-box;
          height: 184px;
          img{
            height: 180px;
            width: 143px;
          }
        }
        .img4-box{
          padding: 4px;
          img{
            height: 176px;
            width: 139px;
          }
        }
        .right {
          margin-left: 35px;
          .name{
            font-size:26px;
            margin-bottom: 20px;
            line-height: 45px;
          }
          .desc{
            font-size:15px;
            color: #666666;
            line-height: 2;
            text-align: justify;
          }
        }
      }
    }
    .upgrade-con{
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      padding-bottom: 30px;
      .upgrade-content {
        display: flex;
        // padding: 0 160px;
        width: 100%;
        box-sizing: border-box;
        .lft{
          width: 40%;
          img{
            // width: 732px;
            // height: 547px;
            width: 100%;
            height: auto;
          }
        }
        .rgt{
          width: 60%;
          box-sizing:border-box;
          margin-left: 50px;
          p{
            font-size: 15px;
            text-indent: 2em;
            line-height: 2;
            margin: 40px 0;
            color: #666;
            text-align: justify;
          }
        }
      }
    }
    .project-con {
      width: 1200px;
      margin: 0 auto ;
      .project-content{
        display: flex;
        justify-content: space-between;
        padding: 75px 0px 70px;
        width: 100%;
        box-sizing: border-box;
        .project-item{
          width: calc(50% - 30px);
          border: 1px solid #dfdfdf;
          background: #fafafa;
          padding: 120px 55px 30px;
          box-sizing: border-box;
          position: relative;
          text-align: center;
          .project-tit{
            width: 150px;
            height: 150px;
            border-radius: 50%;
            background: #adbdbd;
            font-size: 26px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 25px;
            box-sizing: border-box;
            text-align: center;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-75px,-75px);
          }
          .name{
            font-size: 22px;
            margin-bottom: 30px;
          }
          .desc{
            font-size: 15px;
            color:#666666;
            margin-bottom: 35px;
          }
        }
      }
    }
    .tit-box{
      width: 700px;
      display: flex;
      margin: 80px auto 50px;
      p{
        width: 233px;
        height: 2px;
        border-top: 1px #02b1b5 solid;
        margin: 0;
        vertical-align: middle;
      }
      .divider-text{
        padding: 0 15px;
        transform: translateY(-50%);
        display: inline-block;
        font-weight: 700;
        font-size: 34px;
        line-height: 34px;
      }
    }
  }
}
@media screen and (max-width: 960px) {
  .join-container{
    width: 100%;
    background: #F5F5F5;
    p{
      margin: 0;
    }
    .top-box{
      width: 100%;
      height: 3rem;
      background: url(../assets/img/strategicJoin/business-bg2.jpg) right center no-repeat;
      background-size: cover;
    }
    .top-img{
      display: block;
      width: 100%;
      height: auto;
    }
    .develop-box{
      margin: 0.32rem 0;
      display: block;
      background: #ffffff;
      padding: 0 0.35rem 0.05rem;
      .tit{
        font-size: 0.3rem;
        font-weight: 500;
        color: #333333;
        padding-top: 0.35rem;
        padding-bottom: 0.32rem;
        text-align: center;
      }
      .green-font{
        color: #02b2b5;
      }
      .tit-img{
        img{
          width: 100%;
          height: auto;
        }

      }
      .detail,.detail2{
        text-indent: 2em;
        font-size: 0.24rem;
        line-height: 1.8;
        margin-bottom: 0.2rem;
        color: #666;
        text-align: justify;
      }
    }
    .member-box {
      margin: 0.32rem 0;
      display: block;
      background: #ffffff;
      padding: 0 0.35rem 0.05rem;
    }
    .member-tit{
      font-size: 0.3rem;
      font-weight: 500;
      color: #333333;
      padding-top: 0.35rem;
      padding-bottom: 0.32rem;
      text-align: center;
    }
    .member-con{
      width: 100%;
    }
    .member-item{
      display: flex;
      background: #adbebe;
      border-radius: 10px;
      padding: 0.28rem;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 0.24rem;
      .lft{
        padding: 2px;
        background: #fff;
        box-sizing: border-box;
        height: 1.7rem;
        img{
          height: calc(1.7rem - 4px);
          width: 1.35rem;
        }
      }
      .img4-box{
        padding: 4px;
        img{
          height: 176px;
          width: 139px;
          height: calc(1.7rem - 8px);
          width: calc(1.35rem - 4px);
        }
      }
      .right {
        margin-left: 0.24rem;
        .name{
          font-size: 0.24rem;
          margin-bottom: 0.15rem;
          line-height: 2;
          color: #333;
        }
        .desc{
          font-size:0.2rem;
          color: #666666;
          line-height: 1.5;
          text-align: justify;
        }
      }
    }
    .tit-box{
      width: 100%;
      display: flex;
      justify-content: center;
      p{
        width: 2rem;
        height: 2px;
        border-top: 1px #02b1b5 solid;
        margin: 0;
        vertical-align: middle;
      }
      .divider-text{
        transform: translateY(-50%);
        display: inline-block;
        font-size: 0.3rem;
        font-weight: 500;
        color: #333333;
        padding: 0.2rem;
      }
    }
    .upgrade-con{
      margin: 0.32rem 0;
      display: block;
      background: #ffffff;
      padding: 0.6rem 0.35rem 0.15rem;
      .upgrade-content {
        width: 100%;
        box-sizing: border-box;
        margin-top: -0.2rem;
        .lft{
          width: 100%;
          img{
            width: 100%;
            height: auto;
          }
        }
        .rgt{
          p{
            text-indent: 2em;
            font-size: 0.24rem;
            line-height: 1.8;
            margin-bottom: 0.2rem;
            color: #666;
            text-align: justify;
          }
        }
      }
    }
    .project-con {
      margin: 0.32rem 0;
      display: block;
      background: #ffffff;
      padding: 0.6rem 0.35rem 0.15rem;
      .project-content{
        padding: 0.7rem 0px 0;
        width: 100%;
        box-sizing: border-box;
        .project-item{
          width: 100%;
          border: 1px solid #dfdfdf;
          background: #fafafa;
          padding: 1.5rem 0.5rem 0.3rem;
          box-sizing: border-box;
          position: relative;
          text-align: center;
          &:last-of-type{
            margin-top:1.3rem;
          }
          .project-tit{
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            background: #adbdbd;
            font-size: 0.28rem;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 0.5rem;
            box-sizing: border-box;
            text-align: center;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-1rem,-1rem);
          }
          .name{
            font-size: 0.28rem;
            margin-bottom: 0.3rem;
            color: #333;
          }
          .desc{
            font-size: 0.24rem;
            line-height: 1.8;
            margin-bottom: 0.2rem;
            color: #666;
            margin-bottom: 0.3rem;
          }
          img{
            width: 70%;
          }
        }
      }
    }
  }
}
</style>
